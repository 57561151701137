import { graphql, navigate } from 'gatsby';
import { ENABLE_DISCOUNT_CARD } from 'gatsby-env-variables';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageSection from 'ui-kit/page-section/page-section';

// Components
import PageLayout from 'components/layouts/page/page.layout';
import PrescriptionErrorOrNoResults from 'components/prescription-error-or-no-results/prescription-error-or-no-results.component';

import storageHelper from 'util/storageHelper';

import usePageRedirection from 'hooks/usePageRedirection';

import { discountCardLegalPaths } from '../index';
// Styles
import './index.style.scss';

const BirdiSomethingWentWrong = ({ data }: { data: GatsbyTypes.BirdiSomethingWentWrongQuery }) => {
    const { t } = useTranslation();

    useEffect(() => {
        storageHelper.local.removeCurrentDrugInfo();
    }, []);

    const isDiscountCardPageRedirected = usePageRedirection({
        featureFlag: ENABLE_DISCOUNT_CARD
    });

    if (isDiscountCardPageRedirected) {
        return <></>;
    }

    return (
        <div className="birdi-results-error-page">
            <PageLayout
                metaData={{ nodeTitle: t('Something went wrong') }}
                isShortFooter={true}
                hideSpecialtyAddress={true}
                legalPaths={discountCardLegalPaths}
            >
                <PageSection>
                    <PrescriptionErrorOrNoResults
                        title={t('pages.discountCard.somethingWentWrong.title')}
                        subTitle={t('pages.discountCard.somethingWentWrong.subTitle')}
                        variant={'error'}
                        buttonLabel={t('pages.discountCard.somethingWentWrong.tryAgain')}
                        onNavigate={() => navigate('/discount-card')}
                    />
                </PageSection>
            </PageLayout>
        </div>
    );
};

export default BirdiSomethingWentWrong;

export const query = graphql`
    query BirdiSomethingWentWrong($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
